import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './home.css'
import TextAnimation from './TextAnimation';
import './style.css'
import StikyContant from './StikyContant';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

const buttonLine1 = ["Silhouette", "Burberry", "Dita", "Dolce & Gabbana", "Vogue",    "Gucci", "Persol", "Rayban", "Bvlgari", "Giorgio Armani"]
const buttonLine2 = ["Gucci", "Persol", "Rayban", "Bvlgari", "Giorgio Armani",   "Jimmy Choo", "Michael Kors", "Prada", "Versace", "Tory Burch"]
const buttonLine3 = ["Jimmy Choo", "Michael Kors", "Prada", "Versace", "Tory Burch" ,     "Silhouette", "Burberry", "Dita", "Dolce & Gabbana", "Vogue"] 
const allBrands = [
    {
        title: 'Mix',
        images: [
            `${require('./brand/Gucci3.jpg')}`, 
            `${require('./brand/mix2.jpg')}`,
            `${require('./brand/mix3.jpg')}`,
            `${require('./brand/mix1.jpg')}`,
            `${require('./brand/JimmyChoo1.jpg')}`,
            `${require('./brand/mix4.jpg')}`,
        ]
    },
    {
        title: 'Silhouette',
        images: [
            `${require('./brand/Silhouette1.jpg')}`,
            `${require('./brand/Silhouette2.jpg')}`,
            `${require('./brand/Silhouette3.jpg')}`,
        ]
    },
    {
        title: 'Burberry',
        images: [
            `${require('./brand/burberry1.jpg')}`,
            `${require('./brand/burberry2.jpg')}`,
            `${require('./brand/burberry3.jpg')}`,
        ]
    },
    {
        title: 'Dita',
        images: [
            `${require('./brand/Dita1.jpg')}`,
            `${require('./brand/Dita2.jpg')}`,
            `${require('./brand/Dita3.jpg')}`,
        ]
    },
    {
        title: 'Dolce & Gabbana',
        images: [
            `${require('./brand/Dolce&Gabbana1.jpg')}`,
            `${require('./brand/Dolce&Gabbana2.jpg')}`,
            `${require('./brand/Dolce&Gabbana3.jpg')}`,
        ]
    },
    {
        title: 'Emporio Armani',
        images: [
            `${require('./brand/EmporioArmani1.jpg')}`,
            `${require('./brand/EmporioArmani2.jpg')}`,
            `${require('./brand/EmporioArmani3.jpg')}`,
        ]
    },
    {
        title: 'Gucci',
        images: [
            `${require('./brand/Gucci1.jpg')}`,
            `${require('./brand/Gucci2.jpg')}`,
            `${require('./brand/Gucci3.jpg')}`,
        ]
    },
    {
        title: 'Persol',
        images: [
            `${require('./brand/Persol1.jpg')}`,
            `${require('./brand/Persol2.jpg')}`,
            `${require('./brand/Persol3.jpg')}`,
        ]
    },
    {
        title: 'Rayban',
        images: [
            `${require('./brand/Rayban1.jpg')}`,
            `${require('./brand/Rayban2.jpg')}`,
            `${require('./brand/Rayban3.jpg')}`,
        ]
    },
    {
        title: 'Bvlgari',
        images: [
            `${require('./brand/Bvlgari1.jpg')}`,
            `${require('./brand/Bvlgari2.jpg')}`,
            `${require('./brand/Bvlgari3.jpg')}`,
        ]
    },
    {
        title: 'Giorgio Armani',
        images: [
            `${require('./brand/GiorgioArmani1.jpg')}`,
            `${require('./brand/GiorgioArmani2.jpg')}`,
            `${require('./brand/GiorgioArmani3.jpg')}`,
        ]
    },
    {
        title: 'Jimmy Choo',
        images: [
            `${require('./brand/JimmyChoo1.jpg')}`,
            `${require('./brand/JimmyChoo2.jpg')}`,
            // `${require('./brand/JimmyChoo3.jpg')}`,
        ]
    },
    {
        title: 'Michael Kors',
        images: [
            `${require('./brand/MichaelKors1.jpg')}`,
            `${require('./brand/MichaelKors2.jpg')}`,
            `${require('./brand/MichaelKors3.jpg')}`,
        ]
    },
    {
        title: 'Prada',
        images: [
            `${require('./brand/Prada1.jpg')}`,
            `${require('./brand/Prada2.jpg')}`,
            `${require('./brand/Prada3.jpg')}`,
        ]
    },
    {
        title: 'Versace',
        images: [
            `${require('./brand/Versace1.jpg')}`,
            `${require('./brand/Versace2.jpg')}`,
            `${require('./brand/Versace3.jpg')}`,
        ]
    },
    {
        title: 'Tory Burch',
        images: [
            `${require('./brand/ToryBurch1.jpg')}`,
            `${require('./brand/ToryBurch2.jpg')}`,
            // `${require('./brand/ToryBurch3.jpg')}`,
        ]
    },
    {
        title: 'Vogue',
        images: [
            `${require('./brand/Vogue1.jpg')}`,
            `${require('./brand/Vogue2.jpg')}`,
            `${require('./brand/Vogue3.jpg')}`,
        ]
    },


]

const Home2 = () => {

    const [scrollPosition, setScrollPosition] = useState(0);
    const homeAbout = useRef(null);
    const [brands, setBrands] = useState('Mix')
    const [filterBrands, setFilterBrands] = useState()

    useEffect(() => {
        AOS.init({ duration: 1500 });
    })
    useEffect(() => {
        setFilterBrands(allBrands?.filter((el) => el?.title == brands))
    }, [brands, allBrands])
    const handleScroll = () => {
        setScrollPosition(window.scrollY / 6);
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const settings = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: dots => (
            <div className="custom-dots">
                <ul style={{ margin: "0px", padding: "0" }}> {dots} </ul>
            </div>
        ),
        customPaging: i => (
            <div className="custom-dot">
                {i + 1}
            </div>
        )
    };
    const slides = [
        { id: 1, text1: 'ESSENTIAL EYEWEAR', text2: 'TRENDY COLOURS', text3: 'CLASSIC DESIGNS', img: `${require('./images/topSlider0.jpg')}` },
        { id: 2, text1: 'ESSENTIAL EYEWEAR', text2: 'TRENDY COLOURS', text3: 'CLASSIC DESIGNS', img: `${require('./images/topSlider1.jpg')}` },
        { id: 3, text1: 'ESSENTIAL EYEWEAR', text2: 'TRENDY COLOURS', text3: 'CLASSIC DESIGNS', img: `${require('./images/topSlider2.jpg')}` },
        { id: 4, text1: 'ESSENTIAL EYEWEAR', text2: 'TRENDY COLOURS', text3: 'CLASSIC DESIGNS', img: `${require('./images/topSlider3.jpg')}` },
    ];

 


    useEffect(() => {

        const habImg1 = document.querySelector(".habImg1");
        const habImg2 = document.querySelector(".habImg2");
        const habImg3 = document.querySelector(".habImg3");
        const habImg4 = document.querySelector(".habImg4");

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {

                    setTimeout(() => {
                        habImg4.classList.add("active")
                    }, 0);
                    setTimeout(() => {
                        habImg3.classList.add("active")
                    }, 500);
                    setTimeout(() => {
                        habImg2.classList.add("active")
                    }, 1000);
                    setTimeout(() => {
                        habImg1.classList.add("active")
                    }, 1500);

                } else {

                    habImg4.classList.remove("active")
                    habImg3.classList.remove("active")
                    habImg2.classList.remove("active")
                    habImg1.classList.remove("active")

                }
            },
            { rootMargin: "0px", threshold: 0.5 }
        );
        if (homeAbout.current) {
            observer.observe(homeAbout.current);
        }
        return () => {
            if (homeAbout.current) {
                observer.unobserve(homeAbout.current);
            }
        };
    }, [homeAbout]);


    const navigate = useNavigate()

    return (
        <div>
            <Helmet>
                <meta charset="utf-8" />
                <title>Chasmaghar | Premium Eyeglasses Showrooms in anand, 
                Premium Eyeglasses Showrooms in Nadiad,</title>
                <meta name="description" content="Discover the finest collection of premium eyeglasses at Chasmaghar. 
Visit our local showrooms for the latest styles and exceptional customer
 service. Shop today!" />
                <meta name="keywords" content="Chasmaghar, premium eyeglasses, eyeglasses showrooms, luxury eyewear, designer glasses, eyeglasses, buy eyeglasses in anand, 
 buy eyeglasses in nadiad," />
            </Helmet>
            <div style={{ height: '50px' }}></div> 
            <div class=''>
                <Slider {...settings}>
                    {slides.map(slide => (
                        <div key={slide.id} class="slide" >
                            <div class='row m-0 ' style={{ backgroundImage: `url(${slide.img})` }}>
                                <div class='col-xl-8 col-lg-10 col-11 '>
                                    <div class='col-lg-10 m-auto '>
                                        <div class='text-start'>
                                            <p class='homeSliderText playfair ' data-aos="fade-up">{slide?.text1}</p>
                                        </div>
                                        <div class='text-end'>
                                            <p class='homeSliderText playfair' data-aos="fade-up">{slide?.text2}</p>
                                        </div>
                                        <div class='text-start'>
                                            <p class='homeSliderText playfair' data-aos="fade-up">{slide?.text3}</p>
                                        </div>

                                        <div class='text-start mt-3'>
                                            <button class="button" data-aos="fade-up"> <span class='d-flex gap-4' onClick={()=>navigate('/our-product')}> <span>Explore Products</span> <span><i class="bi bi-chevron-right"></i></span></span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>

            <div class='col-11 m-auto row m-0 p-0 my-4 gap-lg-0 gap-5 '>
                <div class='col-lg-4'>
                    <div class=' m-auto text-start d-flex align-items-start h-100'>
                        <div>
                            <p class='display-5' data-aos="fade-up"><span class='fw-bold'>CHAROTARS VISIONARIES WITH 70 YEARS OF</span> <span class='playfair'>EXPERIENCE</span></p>
                            <div class='text-start mt-3'>
                                <button class="button" data-aos="fade-up" onClick={()=>navigate('/about-us')}> <span class='d-flex gap-4'> <span>learn More about us</span> <span><i class="bi bi-chevron-right"></i></span></span></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='col-lg-4'>
                    <div class='habDiv' ref={homeAbout}>
                        <div class='habImg4 habImg'>
                            <img src={require('./images/ha4.jpg')} width='100%' alt="" />
                        </div>
                        <div class='habImg3 habImg'>
                            <img src={require('./images/ha3.jpg')} width='100%' alt="" />
                        </div>
                        <div class='habImg2 habImg'>
                            <img src={require('./images/ha2.jpg')} width='100%' alt="" />
                        </div>
                        <div class='habImg1 habImg'>
                            <img src={require('./images/ha1.jpg')} width='100%' alt="" />
                        </div>
                    </div>
                </div>

                <div class='col-lg-4'>
                    <div class='col-11 m-auto d-flex align-items-end h-100'>
                        <div>
                            <p class='fs-5 tJustify' data-aos="fade-up">Across generations, Chashmaghar has been Charotar's trusted name in eye care. We offer personalized service, cutting-edge expertise, and a wide variety of frames & lenses to meet your vision needs. Visit us in Anand, Vallabh Vidyanagar, or Nadiad!</p>
                        </div>
                    </div>
                </div>

            </div>
            <div>
                <StikyContant />
            </div>
            <div style={{ background: '#D8F5FF' }}>
                <br />
                <div class='col-11 m-auto'>
                    <p class='display-4 fw-semibold' data-aos="fade-up">Empowering Your Vision Through <br />  <span class='fw-normal playfair'>Advanced Diagnostics</span> </p>
                    <div class='row mt-5'>
                        <div class='col-lg-8 text-start'>
                            <div class='row align-items-end'>
                                <div class='col-md-7 '>
                                    <p class='display-4 fw-semibold' data-aos="fade-up">Comprehensive <span class='fw-normal playfair'>Eye Check-up</span> </p>
                                </div>
                                <div class='col-md-5'  >
                                    <div class='col-md-8 m-auto my-3 '>
                                        <img class='eyeWearSmall' data-aos="fade-in-up" src={require('./images/eyewear1.jpg')} width='100%' alt="" />
                                    </div>
                                </div>

                            </div>
                            <div class='col-md-8'>
                                <p class='fs-5  tJustify mt-3' data-aos="fade-up">Experience the future of vision care with our latest machines and equipment, ensuring precise measurements and accurate prescriptions for optimal vision health.</p>
                                <button class="button mt-3" data-aos="fade-up" onClick={()=>navigate('/our-services')}> <span class='d-flex gap-4'> <span>learn More about us</span> <span><i class="bi bi-chevron-right"></i></span></span></button>
                            </div>

                        </div>
                        <div class='col-lg-4'  >
                            <div class='eyewearDumy'>  </div>
                            <div class='col-md-11 m-auto mt-4 ' >
                                <img class='eyeWearLarg' data-aos="fade-in-up" src={require('./images/eyewear2.jpg')} width="100%" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ background: 'white' }}>
                <div>
                    <p class='ourBrandText' data-aos="fade-down">OUR BRANDS</p>
                </div>
                <div class='col-11 m-auto'>
                    <div class='col-md-5 col-sm-6 col-11 text-start'>
                        <p class='display-5 fw-semibold' data-aos="fade-up">Statement Pieces for every individual<span class='playfair  fw-normal'> Explore </span>our designer brands</p>
                    </div>
                </div>
                <div style={{ overflow: 'hidden' }}>

                    <div class='d-flex gap-5  mt-4' style={{ transform: `translateX(-${scrollPosition * 0.9}px)` }}>
                        <div class='col-md-10 col-3 '></div>
                        {
                            buttonLine1?.map((el) => {
                                return (

                                    <div class='catagoryButton' onClick={() => setBrands(el)}>
                                        <p>
                                            {el}
                                        </p>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div class='d-flex gap-5  mt-4' style={{ transform: `translateX(-${scrollPosition * 0.99}px)` }}>
                        <div class='col-md-11 col-4'></div>

                        {
                            buttonLine2?.map((el) => {
                                return (

                                    <div class='catagoryButton' onClick={() => setBrands(el)}>
                                        <p>
                                            {el}
                                        </p>
                                    </div>
                                )
                            })
                        }

                    </div>
                    <div class='d-flex gap-5  mt-4' style={{ transform: `translateX(-${scrollPosition * 1.1}px)` }}>
                        <div class='col-md-12 col-5'></div>
                        {
                            buttonLine3?.map((el) => {
                                return (

                                    <div class='catagoryButton' onClick={() => setBrands(el)}>
                                        <p>
                                            {el}
                                        </p>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </div>

            <div class='mt-5' style={{ background: '#D8F5FF' }}>
                <br />
                <div class='row  col-11 m-auto my-4 h-100'>
                    {
                       filterBrands?.[0]?.images?.map((el,i) => {
                            return (
                                <div class='col-lg-4 col-md-6 my-2 ' data-aos="zoom-in-up">
                                    <div class='col-12 m-auto ourBranchImg h-100'> 
                                        <img src={el}   alt="" />
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
                <br />
            </div>

            <div>
                <div class='branchSection'  >
                    <p class='display-1 text-white playfair' data-aos="fade-up" >Our branches</p>
                    {/* <p class='fs-1 fw-bold text-white playfair my-4' data-aos="fade-up" > <a href="https://chashmaghar.smartwalk.io/" target='_blanck' style={{textDecoration:'none',color:'white'}}>Smart Walk</a></p> */}


                    <div style={{ width: '100%', position: 'absolute', bottom: '0', left: '0', background: 'linear-gradient(rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 5%,rgba(0,0,0,0.7) 10%,rgba(0,0,0,1) 15% ,#000 0)' }}>
                        <br />
                        <div class='col-11 m-auto row align-items-start justify-content-center '>
                            <div class='col-lg-4 col-md-6 mt-4'>
                                <div class='col-11 m-auto text-start' data-aos="fade-right">
                                    <p class='fs-1 text-white playfair'>BRANCH 1</p>
                                    <p class='fs-5 text-white'>Chashmaghar, Opp Municipal Hospital, Station Road, Anand – 388001</p>
                                    <p class='fs-5 text-white'>Mo. : 9099302000, 9099969626</p>

                                    <p class='text-white   my-2'  > <a href="https://maps.app.goo.gl/8X4QBcLwyiR7MRoy9" target='_blanck' style={{ color: 'white' }}>Get Directions <i class="bi bi-arrow-right"></i></a></p>
                                    <a href='https://chashmagharanand.smartwalk.io/' target='_blanck'>
                                        <button class="buttonw mt-2"  > <span class='d-flex gap-4'> <span>Virtual Showroom</span> <span><i class="bi bi-chevron-right"></i></span></span></button>
                                    </a>
                                    <hr class='text-white' />

                                </div>
                            </div>

                            <div class='col-lg-4 col-md-6 mt-4'>
                                <div class='col-11 m-auto text-start' data-aos="fade-right">
                                    <p class='fs-1 text-white playfair'>BRANCH 2</p>
                                    <p class='fs-5 text-white'>Chashmaghar VVN, Opp. Mithash, Chitrangana Complex, Anand- VVN Road, Anand – 388001.</p>
                                    <p class='fs-5 text-white'>Mo. : 9099929348</p>
                                    <p class='text-white   my-2'  > <a href="https://maps.app.goo.gl/GbRX1s8m9ESP7NZr5" target='_blanck' style={{ color: 'white' }}>Get Directions <i class="bi bi-arrow-right"></i></a></p>
                                    {/* <a href='https://chashmaghar.smartwalk.io/' target='_blanck'>
                                        <button class="buttonw mt-2"  > <span class='d-flex gap-4'> <span>Virtual Showroom</span> <span><i class="bi bi-chevron-right"></i></span></span></button>
                                    </a> */}
                                    <hr class='text-white' />
                                </div>
                            </div>

                            <div class='col-lg-4 col-md-6 mt-4'>
                                <div class='col-11 m-auto text-start' data-aos="fade-right">
                                    <p class='fs-1 text-white playfair'>BRANCH 3</p>
                                    <p class='fs-5 text-white'>Chashmagahr Nadiad, 7, Palladium Plaza, Opp. Kheta Talav, Nr. Mahagujarat circle, Nadiad – 387001.</p>
                                    <p class='fs-5 text-white'>Mo. : 9998269677 </p>
                                    <p class='text-white   my-2'  > <a href="https://maps.app.goo.gl/2GyySNpUmKfKu6Fq6" target='_blanck' style={{ color: 'white' }}>Get Directions <i class="bi bi-arrow-right"></i></a></p>
                                    <a href='https://chashmaghar.smartwalk.io/' target='_blanck'>
                                        <button class="buttonw mt-2"  > <span class='d-flex gap-4'> <span>Virtual Showroom</span> <span><i class="bi bi-chevron-right"></i></span></span></button>
                                    </a>
                                    <hr class='text-white' />

                                </div>
                            </div>

                        </div>


                    </div>
                </div>

            </div>

            {/* <div>
                <div class='branchSection' style={{ background: `url(${require('./images/ourBranch.jpg')})` }}>
                    <p class='display-1 text-white playfair' data-aos="fade-up" >Our branches</p>


                    <div style={{ width: '100%', position: 'absolute', bottom: '0', left: '0', background: 'linear-gradient(rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 5%,rgba(0,0,0,0.7) 10%,rgba(0,0,0,1) 15% ,#000 0)' }}>
                        <br />
                        <div class='col-11 m-auto row align-items-center justify-content-center '>
                            <div class='col-lg-4 col-md-6 mt-4'>
                                <div class='col-11 m-auto text-start' data-aos="fade-right">
                                    <p class='fs-1 text-white playfair'>BRANCH 1</p>
                                    <p class='fs-5 text-white'>Chashmaghar, Opp Municipal Hospital, Station Road, Anand – 388001</p>
                                    <p class='fs-5 text-white'>Mo. : 9099302000, 9099969626</p>

                                    <p class='text-white   my-2'  > <a href="https://maps.app.goo.gl/8X4QBcLwyiR7MRoy9" target='_blanck' style={{ color: 'white' }}>Get Directions <i class="bi bi-arrow-right"></i></a></p>
                                    <a href='https://chashmagharanand.smartwalk.io/' target='_blanck'>
                                        <button class="buttonw mt-2"  > <span class='d-flex gap-4'> <span>Virtual Showroom</span> <span><i class="bi bi-chevron-right"></i></span></span></button>
                                    </a>
                                    <hr class='text-white' />

                                </div>
                            </div>

                            <div class='col-lg-4 col-md-6 mt-4'>
                                <div class='col-11 m-auto text-start' data-aos="fade-right">
                                    <p class='fs-1 text-white playfair'>BRANCH 2</p>
                                    <p class='fs-5 text-white'>Chashmaghar VVN, Opp. Mithash, Chitrangana Complex, Anand- VVN Road, Anand – 388001.</p>
                                    <p class='fs-5 text-white'>Mo. : 9099929348</p>
                                    <p class='text-white   my-2'  > <a href="https://maps.app.goo.gl/GbRX1s8m9ESP7NZr5" target='_blanck' style={{ color: 'white' }}>Get Directions <i class="bi bi-arrow-right"></i></a></p>
                                    <a href='https://chashmaghar.smartwalk.io/' target='_blanck'>
                                        <button class="buttonw mt-2"  > <span class='d-flex gap-4'> <span>Virtual Showroom</span> <span><i class="bi bi-chevron-right"></i></span></span></button>
                                    </a>
                                    <hr class='text-white' />
                                </div>
                            </div>

                            <div class='col-lg-4 col-md-6 mt-4'>
                                <div class='col-11 m-auto text-start' data-aos="fade-right">
                                    <p class='fs-1 text-white playfair'>BRANCH 3</p>
                                    <p class='fs-5 text-white'>Chashmagahr Nadiad, 7, Palladium Plaza, Opp. Kheta Talav, Nr. Mahagujarat circle, Nadiad – 387001.</p>
                                    <p class='fs-5 text-white'>Mo. : 9998269677 </p>
                                    <p class='text-white   my-2'  > <a href="https://maps.app.goo.gl/2GyySNpUmKfKu6Fq6" target='_blanck' style={{ color: 'white' }}>Get Directions <i class="bi bi-arrow-right"></i></a></p>
                                   
                                    <hr class='text-white' />

                                </div>
                            </div>

                        </div>


                    </div>
                </div>

            </div> */}


        </div >
    )
}

export default Home2