
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import Home2 from './components/Home2';
import Navabr from './components/Navabr';
import AboutUs from './components/AboutUs';
import OurProduct from './components/OurProduct';
import OurServices from './components/OurServices';
import ContactUs from './components/ContactUs';
import ScrollToTop from './ScrollToTop';
import BookAnApp from './components/BookAnApp';
import { useState } from 'react';
import { RiWhatsappFill } from 'react-icons/ri';

function App() {

  const [appointment, setAppointment] = useState(false)
  const phoneNumber = '9099302000';
  // const message = '🕶 Welcome to Chashmaghar 😎';
  const openWhatsApp = () => {
    // const encodedMessage = encodeURIComponent(message);
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };


  return (
    <div className="App">
      <ScrollToTop />
      <Navabr appState={setAppointment} />

      <Routes>
        <Route path='/' element={<Home2 appState={setAppointment} />} />
        <Route path='/about-us' element={<AboutUs appState={setAppointment} />} />
        <Route path='/our-product' element={<OurProduct appState={setAppointment} />} />
        <Route path='/our-services' element={<OurServices appState={setAppointment} />} />
        <Route path='/contact-us' element={<ContactUs appState={setAppointment} />} />
      </Routes>
      <p class='whatsapp' onClick={openWhatsApp}><RiWhatsappFill /></p>
      {
        appointment &&
        <div class='d-flex align-items-center justify-content-center' style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100vh', zIndex: '10', background: 'rgba(67,67,612,0.8)' }}>
          <BookAnApp appState={setAppointment} />
        </div>
      }
 

      <Footer />

      




    </div>
  );
}

export default App;
