import React, { useEffect, useState } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';
import Swal from 'sweetalert2';

const BookAnApp = ({ appState }) => {
    const [contact, setContact] = useState()

    useEffect(() => {
        AOS.init({ duration: 1500 });
    })

    const contactDetails = (e) => {
        const { name, value } = e.target
        setContact({ ...contact, [name]: value })
    }

    const submitForm = () => {
        if (contact?.name && contact?.name != "" &&
            contact?.phone && contact?.phone != "" &&
            contact?.address && contact?.address != "" &&
            contact?.email && contact?.email != "" &&
            contact?.message && contact?.message != "") {
            axios
                .post('https://hi-labsolution.net/api/chashma-ghar-contact.php', contact)
                .then((res) => {
                    Swal.fire({
                        title: 'Success!',
                        text: res?.data?.responseMessage,
                        icon: 'success',
                        confirmButtonText: 'ok'
                    })
                    setContact({
                        name: "",
                        address: "",
                        phone: "",
                        email: "",
                        message: "",
                    })

                })
                .catch((err) => {
                    Swal.fire({
                        title: 'Error!',
                        text: err?.responseMessage,
                        icon: 'error',
                        confirmButtonText: 'ok'
                    })
                });
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'All fields must be required!',
                icon: 'error',
                confirmButtonText: 'ok'
            })

        }
    }

    useEffect(() => {
        const inputs = document.querySelectorAll('input');
        inputs.forEach(el => {
            el.addEventListener('blur', e => {
                if (e.target.value) {
                    e.target.classList.add('dirty');
                } else {
                    e.target.classList.remove('dirty');
                }
            })
        })
    }, [])
    return (
        <div class='col-lg-9 col-11'>
            <div class='d-flex justify-content-end align-items-center'>
                <i onClick={() => appState(false)} class="bi bi-x-octagon-fill fs-2 text-white" style={{ cursor: 'pointer' }}></i>
            </div>
            <div class='p-3 rounded-5' style={{ backgroundImage: `url(${require('./images/hbBg.jpg')})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%' }}>
                <p class='fs-1 playfair text-white'> BOOK AN APPOINTMENT</p>


                <div class="form__group field">
                    <input type="input" class="form__field" value={contact?.name} placeholder='Name' name='name' onChange={contactDetails} />
                    <label for="name" class="form__label">Name</label>
                </div> 
                <div class="form__group field">
                    <input type="input" class="form__field" value={contact?.address} placeholder='Address' name='address' onChange={contactDetails} />
                    <label for="address" class="form__label">Address</label>
                </div>
                <div class="form__group field">
                    <input type="input" class="form__field" value={contact?.phone} placeholder='Phone' name='phone' onChange={contactDetails} />
                    <label for="phone" class="form__label">Phone</label>
                </div>
                <div class="form__group field">
                    <input type="input" class="form__field" value={contact?.email} placeholder='Email' name='email' onChange={contactDetails} />
                    <label for="email" class="form__label">Email</label>
                </div>
                <div class="form__group field">
                    <input type="input" class="form__field" value={contact?.message} placeholder='Message' name='message' onChange={contactDetails} />
                    <label for="message" class="form__label">Message</label>
                </div>
                <button class="buttonw mt-4" data-aos="fade-up" onClick={submitForm}> <span class='d-flex gap-4'> <span>Submit</span> <span><i class="bi bi-chevron-right"></i></span></span></button>
            </div>
        </div>
    )
}

export default BookAnApp