import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import Faq from './Faq';

const OurServices = ({ appState }) => {
    useEffect(() => {
        AOS.init({ duration: 1500 });
    })


    const phoneNumber = '9099302000';
    // const message = '🕶 Welcome to Chashmaghar 😎';
    const openWhatsApp = () => {
        //   const encodedMessage = encodeURIComponent(message);
        const url = `https://wa.me/${phoneNumber}`;
        window.open(url, '_blank');
    };
    return (
        <div style={{ overflow: 'hidden' }}>
            <Helmet>
                <meta charset="utf-8" />
                <title>Our Services | Chasmaghar Premium Eyeglasses Showrooms</title>
                <meta name="description" content="At Chasmaghar, we offer comprehensive eyewear services, including eye 
exams, custom fittings, and repairs. Visit our local showrooms for 
exceptional service." />
                <meta name="keywords" content="Chasmaghar services, eyewear services, eye exams, custom fittings, 
eyeglasses repairs, premium eyeglasses, local showrooms, eyewear 
services, 
eyewear services in anand, 
eyewear services in vidyanagar, 
eyewear services

in nadiad 
" />
            </Helmet>
            <div style={{ height: '100px' }}></div>

            <div class='row aboutUs align-items-center    '>
                <div class='col-md-5'>
                    <br />
                    <br />
                    <img src={require('./images/s1.jpg')} data-aos="fade-right" style={{ width: '100%' }} alt="" />
                </div>
                <div class='col-md-7 position-relative'>
                    <div class=' position-absolute' style={{ width: '200px', aspectRatio: '1/1', transform: 'rotate(-20deg)', top: '-10%', right: '-20px' }}>
                        <img data-aos="fade-down-left" src={require('./images/topSlider0.jpg')} style={{ width: '100%', height: '100%', aspectRatio: '1/1', borderRadius: '10px' }} alt="" />
                    </div>
                    <div class='col-11 m-auto text-start'>
                        <p class='display-1 playfair' data-aos="fade-up">OUR </p>
                        <p class='display-1 playfair' data-aos="fade-up"> SERVICES</p>

                        <p class='f-5 tJustify' data-aos="fade-up" >Chashmaghar offers comprehensive eye care services tailored to your unique needs. From routine eye examinations and contact lens fittings to specialized treatments like myopia control, our experienced optometrists provide personalized solutions. Utilizing state-of-the-art tools, we ensure precise measurements and optimal fittings for prescription lenses and frames. Whether you're concerned about your vision, seeking style, or managing a specific eye condition, we're here to help you see your best. </p>
                        <button onClick={openWhatsApp} class="button mt-4" data-aos="fade-up"> <span class='d-flex gap-4'> <span>Book Appointment Now</span> <span><i class="bi bi-chevron-right"></i></span></span></button>
                    </div>

                </div>

            </div>

            <div style={{ backgroundImage: `url(${require('./images/serviceBg.jpg')})`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat' }}>
                <div>
                    <p class='ourBrandText' style={{ color: 'rgba(255,255,255,0.2)' }} data-aos="fade-down">OUR SERVICES</p>
                </div>
                {/* 1st */}
                <div class='row align-items-center flex-column-reverse flex-md-row'>
                    <div class='col-md-5'>
                        <div class='col-11 m-auto text-start'>
                            <p class='playfair fs-1 text-white' data-aos="fade-up">Comprehensive Eye Check-Up</p>
                            <p class='text-white tJustify' data-aos="fade-up">Worried about your vision? Our expert optometrists are here to help! We utilize advanced technology to provide comprehensive eye exams, assessing everything from visual clarity to potential eye diseases. Let us ensure your eyes see the world at their best.</p>
                            <button onClick={openWhatsApp} class="buttonw mt-4" data-aos="fade-up"> <span class='d-flex gap-4'> <span>Book Appointment Now</span> <span><i class="bi bi-chevron-right"></i></span></span></button>
                        </div>
                    </div>
                    <div class='col-md-7'>
                        <img class='' src={require('./images/s2.jpg')} data-aos="fade-in" style={{ width: '100%' }} alt="" />

                    </div>
                </div>
                {/* 2nd */}
                <div class='row mt-5'>
                    <div class='col-md-6'>
                        <div class='col-11 m-auto'>
                            <img class='' src={require('./images/os4.jpg')} data-aos="fade-in" style={{ width: '100%' }} alt="" />
                        </div>
                    </div>
                    <div class='col-md-6'>
                        <div class='col-11 m-auto text-start'>
                            <img class='' src={require('./images/os5.jpg')} data-aos="fade-in" style={{ width: '100%' }} alt="" />
                            <p class='playfair fs-1 text-white' data-aos="fade-up">Contact Lens Consultation </p>
                            <p class='text-white tJustify' data-aos="fade-up">Don't settle for blurry vision! Specialty contact lenses can revolutionize your experience with conditions like astigmatism, presbyopia, and keratoconus. At Chashmaghar, our skilled eye care professionals provide personalized fittings for these advanced lenses, guaranteeing exceptional comfort and clear sight. </p>
                            <button onClick={openWhatsApp} class="buttonw mt-4" data-aos="fade-up"> <span class='d-flex gap-4'> <span>Book Appointment Now</span> <span><i class="bi bi-chevron-right"></i></span></span></button>
                        </div>
                    </div>
                </div>
                {/* 3rd */}
                <div class='row align-items-center mt-5 flex-column-reverse flex-md-row'>
                    <div class='col-md-5'>
                        <div class='col-11 m-auto text-start'>
                            <p class='playfair fs-1 text-white' data-aos="fade-up">Myopia Control Clinic </p>
                            <p class='text-white tJustify' data-aos="fade-up">Concerned about your child's worsening nearsightedness (myopia)? At our specialized clinics, we're dedicated to helping children and their parents to help control myopia progression. We understand the anxieties parents face and offer personalized consultations to find the ideal solution for each child. Our team utilizes safe, non-invasive methods like specialized myopia control lenses, proven to slow the progression of nearsightedness. Let us help your child see clearly, now and in the future </p>
                            <button onClick={openWhatsApp} class="buttonw mt-4" data-aos="fade-up"> <span class='d-flex gap-4'> <span>Book Appointment Now</span> <span><i class="bi bi-chevron-right"></i></span></span></button>
                        </div>
                    </div>
                    <div class='col-md-7'>
                        <img class='' src={require('./images/os12.jpg')} data-aos="fade-in" style={{ width: '100%' }} alt="" />
                    </div>
                </div>
                <div class='my-2 p-3 m-auto'>

                    <Faq />
                </div>

                {/* 4th */}
                {/* <div class='row align-items-center mt-5'>
                    <div class='col-md-7'>
                        <img class='' src={require('./images/os6.jpg')} data-aos="fade-in" style={{ width: '100%' }} alt="" />
                    </div>
                    <div class='col-md-5'>
                        <div class='col-11 m-auto text-start'>
                            <p class='playfair fs-1 text-white' data-aos="fade-up">EYEWEAR REPAIRS AND ADJUSTMENTS</p>
                            <p class='text-white tJustify' data-aos="fade-up">Need a repair or adjustment for your glasses? Our skilled technicians are here to help. Whether it's a broken hinge, loose screw, or minor alignment issue, we'll ensure that your eyewear is restored to its optimal condition for maximum comfort and functionality.</p>
                            <button onClick={() => appState(true)} class="buttonw mt-4" data-aos="fade-up"> <span class='d-flex gap-4'> <span>Book Appointment Now</span> <span><i class="bi bi-chevron-right"></i></span></span></button>
                        </div>
                    </div>
                </div> */}

            </div>



        </div >
    )
}

export default OurServices