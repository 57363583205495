import React, { useEffect, useRef, useState } from 'react'
import './stikyContant.css'

const StikyContant = () => {

    const [divSection, setDivSection] = useState("");



    useEffect(() => {
        const horizontalScroll = () => {
            const sticky = document.querySelector(".sticky");
            const stickyParent = document.querySelector(".sticky-parent");

            const scrollWidth = sticky.scrollWidth;
            const verticalScrollHeight =
                stickyParent.getBoundingClientRect().height -
                sticky.getBoundingClientRect().height;

            const stickyPosition = sticky.getBoundingClientRect().top;
            if (stickyPosition > 1) {
                return;
            } else {
                const scrolled = stickyParent.getBoundingClientRect().top;
                sticky.scrollLeft =
                    (scrollWidth / verticalScrollHeight) * -scrolled * 0.75;
            }
        };

        document.addEventListener("scroll", horizontalScroll);

        return () => {
            document.removeEventListener("scroll", horizontalScroll);
        };
    }, []);
    return (
        <div>

            <div className="sticky-parent" style={{ backgroundImage: `url(${require('./images/hbBg.jpg')})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%' }}>
                <div className="sticky">
                    <div className="horizontal">

                        <div className="dim" id="moving" style={{ minWidth: "40vw", color: "white", position: "relative", }}>
                            {/* empty space */}
                        </div>
                        <div className="dim">
                            <div class="locoImg"  >
                                <div class='locoTextDiv'>
                                    <div>
                                        <p class='locoTextDivtext playfair' style={{color:'#4d5064'}}>Spectacle</p>
                                    </div>
                                </div>
                                <div class="locoImgDiv" >
                                    <img src={require("./images/sunGlass2.jpg")} alt="" />
                                </div>
                            </div>
                        </div>

                        <div className="dim">
                            <div class="locoImg"  >
                                <div class='locoTextDiv'>
                                    <div>
                                        <p class=' locoTextDivtext playfair ' style={{color:'#4d5064'}}  >Sunglasses</p>
                                    </div>
                                </div>
                                <div class="locoImgDiv" >
                                    <img src={require("./images/sunGlass1.jpg")} alt="" />
                                </div>
                            </div>
                        </div>

                        <div className="dim">
                            <div class="locoImg"  >
                                <div class='locoTextDiv'>
                                    <div>
                                        <p class=' locoTextDivtext playfair' style={{color:'#4d5064'}}>Contact lenses</p>
                                    </div>
                                </div>
                                <div class="locoImgDiv" >
                                    <img src={require("./images/sunGlass3.jpg")} alt="" />
                                </div>
                            </div>
                        </div>

                        <div className="dim">
                            <div class="locoImg"  >
                                <div class='locoTextDiv '>
                                    <div>
                                        <br /><br />
                                        <p class='locoTextDivtext playfair ' style={{color:'#4d5064'}}  >Prescription lenses</p>
                                    </div>
                                </div>
                                <div class="locoImgDiv" >
                                    <img src={require("./images/sunGlass4.jpg")} alt="" />
                                </div>
                            </div>
                        </div>

                        {/* <div className="dim">
                            <div class="locoImg"  >
                                <div class='locoTextDiv'>
                                    <div>
                                        <p class='display-1 playfair text-dark'>Sunglasses</p>
                                    </div>
                                </div>
                                <div class="locoImgDiv" >
                                    <img src={require("./images/sunGlass2.jpg")} alt="" />
                                </div>
                            </div>
                        </div> */}

                     


                    </div>
                </div>
            </div>

        </div>
    )
}

export default StikyContant