import React from 'react'

const faq = [ 
    {
        qus:'What are blue filter lenses?',
        ans: '<b>Blue light filter lenses</b> are designed to block or reduce the amount of blue light mitted from digital screens and artificial lighting. By filtering out a portion of the blue light, these lenses can help improve sleep quality, reduce digital eye strain, and  offer some relief from headaches associated with prolonged screen time.'
    },
    {
        qus:'What are anti reflection lenses?',
        ans: '<b>Anti-reflective lenses have a coating with</b>  thin layers applied to the surface of lenses to minimize glare and reflections. They improve visual clarity, reduce eye strain, and enhance the aesthetic appeal of glasses by making the lenses less visible. Such coatings are particularly beneficial for people who spend extended hours in front of screens or drive at night.'
    },
    {
        qus:'What are photochromatic lenses and how are they different from clear lenses?',
        ans: "<b>Photochromic lenses</b> are like magic for your glasses! They automatically adjust to changing light conditions. When you're indoors or in low light, they're clear like regular glasses. But step outside into the sun, and they darken to protect your eyes from harmful UV rays, just like sunglasses. It's like having two pairs of glasses in one!"
    },
    {
        qus:'What are progressive lenses?',
        ans: '<b>Progressive lenses</b> are a modern solution for individuals experiencing presbyopia, an age-related condition that affects near vision. Unlike traditional bifocals with noticeable lines, progressive lenses offer a seamless transition between different vision zones. This innovative design allows wearers to see clearly at all distances – from faraway objects to close-up text – without constantly switching between glasses.'
    },
    {
        qus:'How do I adapt to progressive lenses?',
        ans: '<b>Adapting to progressive lenses</b> takes time and patience. Start by wearing them consistently, even indoors. Move your head instead of just your eyes to find the correct focal point. Be patient as your brain adjusts to the new lens design. If discomfort persists, consult the team of optometrists at Chashmaghar'
    },
    {
        qus:'What are polarized lenses and why should one get polarized sunglasses?',
        ans: '<b>Polarized lenses</b> are special lenses that reduce glare by filtering out light that reflects horizontally. Polarized lenses offer enhanced visual clarity and comfort by significantly reducing glare from reflective surfaces like water, snow, and roads. This reduction in eye strain improves vision, enhances color perception, and ultimately provides a safer and more enjoyable outdoor experience.'
    },
    {
        qus:'What are high-index lenses?',
        ans: '<b>High-index lenses</b> are a technological advancement in eyewear designed to address the issue of thick, heavy lenses often associated with high prescriptions. These lenses utilize a special material that bends light more efficiently, allowing for thinner and lighter lenses without compromising visual clarity.'
    },
    {
        qus:'How should I care for my spectacles?',
        ans: "To maintain your spectacles' clarity and longevity, gently clean them with a soft microfiber cloth and water, avoiding harsh chemicals or rough materials. Always store them in a protective case when not in use, and schedule regular adjustments with Chashmaghar. Additionally, protect your glasses from extreme temperatures and handle them carefully to prevent damage"
    },
    {
        qus:'How should I care for my contact lenses?',
        ans: "Always wash and dry your hands before handling lenses. Use the prescribed lens solution to clean, rinse, and store your contacts in a clean case. Replace your contact lenses and lens case as recommended by your eye care professional. Avoid water contact with your lenses and follow your optometrist's guidelines for wear time"
    },
    {
        qus:'What is Myopia in kids?',
        ans: '<b>Myopia,</b> or nearsightedness, is a common eye condition where you can see close objects clearly but have trouble seeing things far away. It is a refractive error where light focuses in front of the retina instead of directly on it, resulting in blurred distance vision. This occurs due to an elongated eyeball or an excessively curved cornea. We at Chashmaghar have a team of qualified and experienced eye care professionals to guide you to choose the most efficient treatment to control myopia in your kids'
    },
    {
        qus:'Is it possible to make prescription swimming goggles?',
        ans: ' Yes, we can customise swimming googles based on your prescription.'
    },
     

]

const Faq = () => {
    return (
        <div class=' ' style={{ background: ' ', overflow: 'hidden' }}>
            {/* <div class='position-relative'>
                <div >
                    <p class='ourBrandText' style={{ color: 'white' }} data-aos="fade-down">FAQ</p>
                </div>
            </div> */} 
            <p class='playfair fs-1 text-white' data-aos="fade-up">FAQ</p>

            <div>
                {
                    faq?.map((el,i) => {
                        return (
                            <div class='  rounded-3  p-2 mt-3  text-start bg-white' style={{border:'0.5px solid #56D6FF'}} data-bs-toggle="collapse" href={`#faq${i}`} role="button" aria-expanded="false" aria-controls={`faq${i}`}>
                                <div class=' px-2 d-flex align-items-center justify-content-between  fw-bold'>
                                    <p>
                                        {el?.qus}
                                    </p>
                                    <p>
                                    <i class="bi bi-chevron-down"></i>
                                    </p>
                                </div>

                                <div class="collapse"  id={`faq${i}`}>
                                    <div class="card card-body border-0 " style={{ background:'transparent'  }}>
                                        <p dangerouslySetInnerHTML={{ __html: el?.ans }}></p>
                                    </div>
                                </div>

                            </div>
                        )
                    })
                }
            </div>
            <br />
        </div>
    )
}

export default Faq