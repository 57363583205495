import React, { useState, useEffect, useRef } from 'react';
import './textAnimation.css';

const TextAnimation = ({text,css,speed}) => {
  const initialText = text
  const [animatedText, setAnimatedText] = useState([initialText]);
  const containerRef = useRef();

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,  
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
         
          const words = initialText.split(' ');

        
          const animatedWords = words.map((word, index) => (
            <span
              key={index}
              style={{ animationDelay: `${index * speed}s` }}
            >
              {word}
            </span>
          ));

          // Join the animated words with spaces to maintain the sentence structure
          setAnimatedText(animatedWords);

          observer.disconnect(); // Stop observing once it's visible
        }
      });
    }, options);

    observer.observe(containerRef.current);

    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, [containerRef]);

  return (
    <div className="textSwift" ref={containerRef}>
      <p className={`swift-up-text  ${css}`}>
        {animatedText.reduce((prev, curr) => [prev, ' ', curr])}
      </p>
    </div>
  );
};

export default TextAnimation;

